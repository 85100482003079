%heading-base {
  font-weight: 700;
  font-family: $font-heading;
  line-height: 1.1;
}

@mixin h1() {
  @extend %heading-base;

  color: $color-primary;
  font-size: rem(28);

  @include mq(md) {
    font-size: rem(40);
  }
}

@mixin h2() {
  @extend %heading-base;

  font-size: rem(24);

  @include mq(md) {
    font-size: rem(28);
  }
}

@mixin h3() {
  @extend %heading-base;

  font-size: rem(20);

  @include mq(md) {
    font-size: rem(24);
  }
}

@mixin h4() {
  @extend %heading-base;

  font-size: rem(18);

  @include mq(md) {
    font-size: rem(20);
  }
}

@mixin h5() {
  @extend %heading-base;

  font-size: rem(18);
}

@mixin h6() {
  @extend %heading-base;

  font-size: rem(16);
}

@mixin intro() {
  font-weight: $font-weight-medium;
  font-size: rem(18);

  @include mq(md) {
    font-size: rem(22);
  }
}

@mixin p() {
  font-size: rem(16);
  line-height: $font-base-line-height;

  @include mq(md) {
    font-size: rem(18);
  }
}

@mixin p-sm() {
  font-size: rem(16);
  line-height: $font-base-line-height;
}

body {
  color: $color-text;
  font-family: $font-fallback;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html.fonts-loaded {
  body {
    font-family: $font-base;
  }
}

p {
  margin: 0 0 $global-margin/2;
}

li {
  padding-bottom: $global-padding/4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $global-margin*0.75;
}

h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4();
}

h5 {
  @include h5();
}

h6 {
  @include h6();
}

p {
  @include p();

  + h1,
  + h2,
  + h3,
  + h4 {
    margin-top: $global-margin * 1.5;
  }
}
