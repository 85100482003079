/*  Block
\*----------------------------------------------------------------------------*/

/*  Elements
\*----------------------------------------------------------------------------*/
.t-team__inner {
  position: relative;
  padding: $global-padding;
  overflow: hidden;
  cursor: pointer;
  transition: $setting-transition;

  &:hover,
  &:focus {
    box-shadow: $global-box-shadow-extend;
    transform: translateY(-0.25rem);
  }
}

.t-team__content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:has(:not(.a-image__img)) {
    .t-team__content-head {
      width: 100%;
      margin-bottom: rem(16);
      padding-right: 0;
    }
  }
}

.t-team__content-head {
  flex-shrink: 0;
  width: 70%;
  padding-right: $global-padding;

  @include mq(md) {
    padding-top: $global-padding;
    padding-right: rem(6);
  }
}

.t-team__meta {
  display: block;
  line-height: 1.4;
  opacity: 0.8;

  .t-team__name + & {
    margin-top: -#{$global-margin / 2};
  }
}

.t-team__image-wrapper {
  position: relative;
  top: 0;
  right: 0;
  width: rem(160);
  height: rem(170);
  transition: $setting-transition-fast;

  @include mq(md) {
    right: -#{$global-margin};
  }

  &::after {
    position: absolute;
    top: 0;
    right: -1%;
    width: 105%;
    height: 105%;
    background: $color-gray;
    content: "";
    clip-path: url(#simpleHexagon);
  }

  &.is-active {
    transform: scale(0.8) translate3d(25px, -25px, 0);
  }

  &:has(:not(.a-image__img)) {
    display: none;
  }
}

.t-team__image {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: url(#simpleHexagon);
}

.t-team__content {
  transition: $setting-transition-fast;

  &.v-enter-active {
    transform: translateY(-#{$global-margin});
  }

  p {
    font-size: $font-size-small;

    &:first-child {
      margin-top: 0;
    }
  }
}

.t-team__toggle {
  @include clean-button();

  display: flex;
  align-items: center;
  color: $color-secondary;
  cursor: pointer;
  transition: $setting-transition-fast;

  &:hover,
  &:focus {
    color: $color-secondary-hover;
  }
}

.t-team__toggle-icon {
  flex-shrink: 0;
  width: rem(14);
  height: rem(14);
  margin-left: $global-margin/4;
  color: $color-secondary;
  transition: $setting-transition;

  .t-team__inner:hover &,
  .t-team__inner:focus & {
    transform: translateX(0.5rem);
  }

  .is-active & {
    transform: rotate(-90deg);

    .t-team__inner:hover &,
    .t-team__inner:focus & {
      transform: rotate(-90deg);
    }
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
