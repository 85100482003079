// =============================================================================
// PX to EM calc (unitless)
// =============================================================================

$browser-context: 16;

@function em($pixels, $context: $browser-context, $unit: 1em) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * $unit;
}

@function rem($pixels, $context: $browser-context, $unit: 1rem) {
  @return em($pixels, $context, 1rem);
}

// =============================================================================
// Aspect Ratio
// =============================================================================

@function aspect-ratio-padding($width, $height) {
  @return ($height / $width) * 100%;
}
