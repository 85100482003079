.animated-component {
  transform: translateY(2rem);
  visibility: hidden;
  opacity: 0;
  transition: $setting-transition-fast;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition-delay: 0.3s * $i;
    }
  }

  &.in-view {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
