$alert-background: lighten($color-secondary, 50%);
$alert-color: darken($color-secondary-hover, 5%);

/*  Block
\*----------------------------------------------------------------------------*/
.m-notification {
  position: absolute;
  top: -100%;
  display: none;
  background: $color-background;

  &.is-active {
    position: relative;
    display: block;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-notification__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $global-margin;
  padding: $global-padding/2;
  color: $alert-color;
  background: $alert-background;
  border: 1px solid $alert-color;
  border-radius: $global-radius;

  @include mq(lg) {
    margin: $global-margin/2 $global-margin*1.5;
    padding-left: $global-padding;
  }
}

.m-notification__content {
  position: relative;
  padding-right: $global-padding / 2;
  padding-left: $global-padding;

  a {
    color: $alert-color;
  }
}

.m-notification__raw > * {
  font-weight: $font-weight-medium;
  font-size: $font-size-small;

  &:last-child {
    margin-bottom: 0;
  }
}

.m-notification___svg-icon {
  position: absolute;
  top: 1px;
  left: 0;
  font-size: rem(24);
}

.m-notification__button {
  @include clean-button();

  display: flex;
  align-items: center;
  justify-content: center;
  width: $global-margin;
  height: $global-margin;
  font-size: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: $setting-transition;

  &:hover,
  &:focus {
    color: $color-white;
    background: $color-secondary-hover;
  }
}

.m-notification___toggle-icon {
  font-size: rem(14);
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
