/*  Block
\*----------------------------------------------------------------------------*/
.m-partners {

  .o-grid {
    justify-content: space-between;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-partners__logos {
  align-items: center;
}

.m-partners__logo {
  max-width: rem(200);
  max-height: rem(80);
  margin-bottom: $global-margin/2;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
