/*  Block
\*----------------------------------------------------------------------------*/
.m-hero {
  position: relative;
  margin-bottom: $global-margin;
  padding: ($global-padding * 1.5) 0 ($global-padding * 4);
  overflow: hidden;
  background: $color-background;

  &.with-image {
    padding: ($global-padding * 1.5) 0;

    @include mq(md) {
      height: calc(640px - #{$setting-header-height});
    }

    @include mq(lg) {
      height: calc(640px - #{$setting-header-height});
    }

    + .o-component {
      margin-top: -#{$global-margin * 6};

      @include mq(md) {
        margin-top: -#{$global-margin * 8};
      }

      @include mq(lg) {
        margin-top: -#{$global-margin * 8};
      }
    }
  }

  &.m-hero--home {
    + .o-component {
      margin-top: -#{$global-margin * 6};

      @include mq(md) {
        margin-top: -#{$global-margin * 4};
      }
    }
  }

  + .o-component {
    position: relative;
    z-index: 1;
    margin-top: -#{$global-margin * 4};

    @include mq(md) {
      margin-top: -#{$global-margin * 4};
    }

    @include mq(lg) {
      margin-top: -#{$global-margin * 4};
    }
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/

.m-hero__image-wrapper {
  width: 100vw;
  height: 100vw;
  margin-right: -#{$global-margin * 2};
  margin-left: auto;
  background: $color-gray;
  clip-path: url(#simpleHexagon);

  @include mq(md) {
    clip-path: url(#complexHexagon);
  }

  @include mq(sm-only) {
    position: relative;
    right: 0;
    width: 40vw;
    height: 40vw;
    margin-top: -#{$global-margin * 3};
  }

  @include mq(md) {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(340);
    height: rem(340);
  }

  @include mq(lg) {
    width: rem(500);
    height: rem(500);
  }

  @include mq(lgxx) {
    width: rem(640);
    height: rem(640);
  }
}

.m-hero__image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.m-hero__content {
  position: relative;
  z-index: 1;
  max-width: rem(500);

  @include mq(sm-only) {
    right: 0;

    .m-text-intro {
      max-width: 60vw;
    }
  }

  @include mq(md) {
    max-width: rem(550);
    padding-top: $global-padding * 2;
  }
}

.m-hero__title {
  margin-bottom: $global-margin / 4;

  @include mq(md) {
    margin-bottom: $global-margin / 2;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
