/*  Block
\*----------------------------------------------------------------------------*/

/*  Elements
\*----------------------------------------------------------------------------*/
.m-services__inner {
  position: relative;
}

.m-services__overview {
  @include clean-list();

  display: flex;
  flex-wrap: wrap;
  margin: $global-margin 0;
}

.m-services__item {
  width: 100%;
  margin-top: -1px;
  padding: 0;

  @include mq(sm) {
    width: 50%;
  }

  @include mq(md) {
    width: percentage($number: 1/3);
  }
}

.m-services__link {
  @include clean-link();

  display: flex;
  align-items: center;
  height: 100%;
  padding: $global-padding/2;
  background: $color-white;
  border: 1px solid $color-gray;
  transition: $setting-transition;

  @include mq(sm) {
    display: block;
    padding: ($global-padding * 0.75) $global-padding $global-padding;
  }

  &:focus,
  &:hover {
    box-shadow: 0 6px 15px $color-gray;
    transform: translateY(-6px) scaleX(1.05);
  }
}

.m-services__item-title {
  position: relative;
  margin: 0;
  font-size: rem(18);
  transition: $setting-transition;

  @include mq(sm) {
    font-size: rem(20);
  }

  .m-services__link:hover &,
  .m-services__link:focus & {
    color: $color-anchor-hover;
  }
}

.m-services__icon-wrapper {
  position: relative;
  margin-right: $global-margin;
}

.m-services__icon-background {
  position: absolute;
  left: rem(2);
  z-index: 0;
  font-size: 3rem;
  opacity: 0.15;
}

.m-services__icon {
  margin-bottom: $global-margin / 4;
  font-size: 2em;

  @include mq(md) {
    margin-bottom: $global-margin;
    font-size: 2.5em;
  }
}

.m-services__arrow {
  display: none;

  @include mq(md) {
    position: absolute;
    right: 0;
    display: inline-block;
    color: $color-anchor-hover;
    visibility: hidden;
    opacity: 0;
    transition: $setting-transition;

    .m-services__link:hover &,
    .m-services__link:focus & {
      visibility: visible;
      opacity: 1;
    }
  }
}

.m-services__nav-link {
  display: block;
  text-align: right;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
