/*  Block
\*----------------------------------------------------------------------------*/
.a-nav-toggle {
  @include clean-button;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: rem(64);
  width: rem(64);
  background: $color-white;
  cursor: pointer;

  &.is-active {
    .a-nav-toggle__item:nth-child(1) {
      transform: translateX(15%) rotate(45deg);
    }
    .a-nav-toggle__item:nth-child(2) {
      opacity: 0;
      transform: scale(.5);
    }
    .a-nav-toggle__item:nth-child(3) {
      transform: translateX(15%) rotate(-45deg);
    }
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-nav-toggle__inner {
  position: relative;
  height: rem(18);
  width: rem(26);
}

.a-nav-toggle__item {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: rem(3);
  background: $color-anchor;
  transform: translateY(-50%);
  transform-origin: left center;
  transition: $setting-transition;

  &:first-child {
    top: 0;
  }

  &:last-child {
    top: 100%;
  }
}


/*  Modifiers
\*----------------------------------------------------------------------------*/
