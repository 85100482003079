// Styling based on https://github.com/spatie/vue-tabs-component

/*  Block
\*----------------------------------------------------------------------------*/
.tabs-component {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: $color-white;
  border: $global-radius;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;

  @include mq(md) {
    flex-direction: row;
  }

  img:not(.tabs-component__icon) {
    max-width: 100%;
    margin: $global-margin/2 0 $global-margin;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.tabs-component-tabs {
  @include clean-list();

  flex-shrink: 0;
  min-width: rem(200);
  padding: $global-padding $global-padding/2;
  background: $color-background;
}

.tabs-component-panel {
  width: 100%;
  padding: $global-padding ($global-padding / 2);
  visibility: hidden;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;

  @include mq(md) {
    padding: ($global-padding * 1.5) ($global-padding * 2) $global-padding;
  }
}

.tabs-component-tab-a {
  @include clean-link;

  display: flex;
  align-items: center;
  padding: ($global-padding / 4) ($global-padding / 2);
  transition: $setting-transition;

  &:hover,
  &:focus {
    color: $color-secondary;
    background: $color-white;
    border-radius: 1px;
    box-shadow: 0 0 0 4px $color-white;
  }

  .is-active & {
    color: $color-secondary;
    font-weight: $font-weight-bold;
    background: $color-white;
    border-radius: 1px;
    box-shadow: 0 0 0 4px $color-white;
  }

  @include mq(lg) {
    padding: ($global-padding / 2) ($global-padding / 2);
    font-size: $font-size-large;
  }
}

.tabs-component__icon {
  width: rem(24);
  height: rem(22);
  margin-right: $global-margin / 3;
  object-fit: contain;
  font-family: 'object-fit: contain;';

  &.icon-lung {
    height: rem(20);
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/

/*  Animations
\*----------------------------------------------------------------------------*/

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
