/*  Block
\*----------------------------------------------------------------------------*/
.o-app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/*  Elements
\*----------------------------------------------------------------------------*/

.o-app__header,
.o-app__footer {
  flex: none;
  background: $color-background;
}

.o-app__main {
  position: relative;
  flex: 1 0 auto;

  // @TODO: Remove without fixed header
  // padding-top: $setting-header-height;

  // @include mq(nav-breakpoint) {
  //   padding-top: $setting-header-height--lg;
  // }
}

.o-app__footer {
  position: relative;
  z-index: 2;
}
