/*  Alignment
\*----------------------------------------------------------------------------*/
.u-align-left {
  text-align: left !important;
}

.u-align-right {
  text-align: right !important;
}

.u-align-center {
  text-align: center !important;
}

/*  Placement
\*----------------------------------------------------------------------------*/
.u-at-bottom {
  margin-top: auto;
}

/*  Text color
\*----------------------------------------------------------------------------*/
.u-color-white {
  color: white;
}

/*  Visibility
\*----------------------------------------------------------------------------*/
[v-cloak] {
  display: none;
}

.u-screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.u-skip-link {
  position: absolute;
  top: rem(16);
  left: rem(16);
  width: 1px;
  height: 1px;
  padding: rem(16);
  overflow: hidden;
  color: black;
  font-weight: 600;
  text-decoration: none;
  background-color: white;
  clip: rect(0 0 0 0);

  &:focus {
    z-index: 100000;
    display: inline-block;
    width: auto;
    height: auto;
    color: $color-anchor;
    clip: auto !important;
  }
}

.u-visible {
  display: block;
}

.u-hidden {
  display: none !important;
}

@each $key, $value in $site-breakpoints {
  .u-visible\@#{$key} {
    display: none;
  }

  @include mq($key) {
    .u-visible\@#{$key} {
      display: block;
    }

    .u-hidden\@#{$key} {
      display: none !important;
    }
  }
}

/*  Positioning
\*----------------------------------------------------------------------------*/
@each $key, $value in $site-breakpoints {
  @include mq($key) {
    .order-first\@#{$key} {
      order: -1;
    }
  }
}

/* =========================================================================
*
* Helpers for spacing
* -- Creates classes for adding padding or margin
* -- Uses steps of 8 pixels
* -- (e.g. `u-mb-2` which means margin bottom: 1.6rem)
*
* ====================================================================== */

$sides: (
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@for $i from 0 through 12 {
  @each $prefix, $value in $sides {
    @each $key, $breakpoint in $site-breakpoints {
      .u-m#{$prefix}-#{$i} {
        margin-#{$value}: #{($i * 5) / 10}rem !important;
      }

      .u-p#{$prefix}-#{$i} {
        padding-#{$value}: #{($i * 5) / 10}rem !important;
      }

      @include mq($key) {
        .u-m#{$prefix}-#{$i}\@#{$key} {
          margin-#{$value}: #{($i * 5) / 10}rem !important;
        }

        .u-p#{$prefix}-#{$i}\@#{$key} {
          padding-#{$value}: #{($i * 5) / 10}rem !important;
        }
      }
    }
  }
}
