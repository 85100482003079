/*  Block
\*----------------------------------------------------------------------------*/
.a-link {
  display: flex;
  align-items: center;
  color: $color-anchor-hover;
  transition: $setting-transition;

  &:hover,
  &:focus {
    color: $color-anchor;
    text-decoration: underline;
  }

  .o-footer & {
    color: $color-white;
    text-decoration: none;
  }

  .t-contact__right & {
    font-size: rem(17);
    text-decoration: none;
    word-break: break-word;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-link__svg {
  flex-shrink: 0;
  margin-right: $global-margin / 3;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.a-link--arrow {
  text-decoration: none;
  cursor: pointer;

  svg {
    display: inline-block;
    width: rem(14);
    height: rem(14);
    margin-top: 0;
    margin-left: $global-margin/4;
    vertical-align: bottom;
    transition: transform $setting-transition;
    fill: currentColor;
  }

  &:focus,
  &:hover {
    color: $color-anchor;
    text-decoration: none;

    svg {
      transform: translateX(#{$global-margin/8});
    }
  }
}
