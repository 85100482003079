/*  Block
\*----------------------------------------------------------------------------*/
.o-footer {
  margin-top: $global-margin * 2;
  padding: $global-padding 0 0;
  color: $color-white;
  background: $color-primary-dark;

  .m-featured-block {
    max-width: rem(500);
    height: auto;
    margin-top: -#{$global-margin * 2};
    margin-bottom: $global-margin;
  }

  p {
    font-size: rem(16);
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.o-footer__title {
  margin: $global-margin/2 0 $global-margin/3;
  font-size: rem(18);
}

.o-footer__list {
  @include clean-list();
}

.o-footer__list-item {
  padding: 0 0 $global-padding/4;
}

.o-footer__link {
  color: $color-white;

  &:hover,
  &:focus {
    color: $color-white;
    text-decoration: underline;
  }
}

.o-footer__social {
  @include clean-list();

  display: flex;
  margin: $global-margin/3 -#{$global-margin/4};

  @include mq(lg) {
    margin-bottom: $global-margin;
  }

  > * {
    padding: 0 ($global-margin/4);
  }
}

.o-footer__social-svg {
  color: $color-white;
  transition: $setting-transition;

  &:hover,
  &:focus {
    transform: translateY(-0.2rem);
  }
}

.o-footer__partner-logo {
  width: rem(200);
}

.o-footer__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: $global-padding/2;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .o-footer__copyright,
  .o-footer__madeby {
    font-size: $font-size-smallest;
  }
}

.o-footer__menu-list {
  @include clean-list();

  display: flex;
  align-items: center;
}

.o-footer__menu-item {
  margin-bottom: $global-margin/3;

  &:not(:last-of-type) {
    padding-right: $global-padding/4;
  }
}

.o-footer__menu-link {
  font-size: $font-size-smallest;
}

.o-footer__madeby-link {
  color: $color-white;
  font-weight: $font-weight-bold;
}

.o-footer__sticky {
  position: fixed;
  right: 1.6rem;
  bottom: 1.6rem;

  @include mq(md) {
    right: 2.4rem;
    bottom: 2.4rem;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
