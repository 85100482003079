$site-breakpoints: (
  'smx-only': '(min-width: 0) and (max-width: 31.98rem)',
  'sm': '(min-width: 32rem)',
  'sm-only': '(min-width: 32rem) and (max-width: 47.98rem)',
  'sm-down': '(max-width: 48rem)',
  'md': '(min-width: 48rem)',
  'md-only': "(min-width: 48rem) and (max-width: 64rem))",
  'md-down': "(max-width: 63.99rem)",
  'lg' : "(min-width: 64rem)",
  'lg-only' : "(min-width: 64rem) and (max-width: 75rem)",
  'lg-down' : "(max-width: 74.99rem)",
  'lgx' : "(min-width: 75rem)",
  'lgx-only' : "(min-width: 75rem) and (max-width: 90rem)",
  'lgx-down' : "(max-width: 74.99rem)",
  'nav-breakpoint' : "(min-width: 64rem)",
  'nav-breakpoint-down' : "(max-width: 63.99rem)",
  'lgxx' : "(min-width: 90rem)",
  'lgxx-only' : "(min-width: 90rem) and (max-width: 112.5rem)",
  'lgxx-down' : "(max-width: 112.49rem)",
  'lgxxx' : "(min-width: 120rem)",
);

@mixin mq($name) {
  @if $name == 'landscape' or $name == 'portrait' {
    $mq-media-query: 'screen and (orientation: #{$name})';

    @media #{$mq-media-query} {
      @content;
    }
  }

  @else if $name == 'retina' {
    $mq-media-query: 'screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx)';

    @media #{$mq-media-query} {
      @content;
    }
  }

  @else {
    @if map-has-key($site-breakpoints, $name) {
      $mq-media-query : 'screen and #{map-get($site-breakpoints, $name)}';

      @media #{$mq-media-query} {
        @content;
      }
    }

    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$site-breakpoints}`. " + "Please make sure it is defined in `$breakpoints` map.";
    }
  }
}
