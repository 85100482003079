%btn {
  @include clean-button();

  display: inline-block;
  padding: rem(12) rem(16);
  color: $color-primary;
  font-weight: $font-weight-medium;
  text-decoration: none;
  background: transparent;
  border: 2px solid $color-primary;
  border-radius: $setting-border-radius;
  cursor: pointer;
  transition: $setting-transition;

  @include mq(sm-down) {
    padding: rem(12) rem(12);
    font-size: rem(15);
  }

  &:hover,
  &:focus {
    color: $color-white;
    background: $color-primary;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/*  Block
\*----------------------------------------------------------------------------*/
.a-btn {
  @extend %btn;
}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-btn__icon {
  display: inline-block;
  margin-left: $global-margin / 6;
  font-size: 0.8em;
  vertical-align: middle;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
%a-btn--primary,
.a-btn--primary {
  color: $color-white;
  background: $color-secondary;
  border-color: $color-secondary;

  &:hover,
  &:focus {
    background: $color-secondary-hover;
    border-color: $color-secondary-hover;
  }
}

%a-btn--secondary,
.a-btn--secondary {
  color: $color-white;
  background: $color-orange;
  border-color: $color-orange;

  &:hover,
  &:focus {
    background: $color-orange-darker;
    border-color: $color-orange-darker;
  }
}

%a-btn--white,
.a-btn--white {
  color: $color-secondary;
  background: $color-white;
  border-color: $color-white;

  &:hover,
  &:focus {
    color: $color-white;
    background: $color-secondary;
  }
}
