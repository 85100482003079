/*  Block
\*----------------------------------------------------------------------------*/
.m-panel {
  border-bottom: 2px solid $color-gray;

  + .m-panel {
    margin: 0;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-panel__toggle {
  @include clean-button();

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $global-padding/2 0;
  cursor: pointer;
}

.m-panel__title {
  @include h4();

  margin: 0;
}

.m-panel__content {
  overflow: hidden;
  transition: height 0.25s ease-in;
}

.m-panel__inner {
  padding-bottom: $global-padding;
}

.m-panel__toggle-icon {
  flex-shrink: 0;
  width: rem(16);
  height: rem(16);
  margin-left: $global-margin/4;
  color: $color-secondary;
  transform: rotate(90deg);
  transition: $setting-transition;

  .is-open & {
    transform: rotate(270deg);
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
