/*  Block
----------------------------------------------------------------------------*/
.o-grid {
  display: flex;
  flex-flow: row wrap;
}

/*  Elements
----------------------------------------------------------------------------*/
.o-grid__col {
  flex-basis: 100%;
  min-width: 0;
  margin-bottom: $global-margin / 2;
}

/*  Modifiers
----------------------------------------------------------------------------*/
.o-grid__col--align-center {
  align-items: center;
}

.o-grid__col--align-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.o-grid--gutter {
  margin-left: -$setting-layout-gutter--sm;

  @include mq(md) {
    margin-left: -$setting-layout-gutter--md;
  }

  @include mq(lg) {
    margin-left: -$setting-layout-gutter--lg;
  }

  @include mq(lgx) {
    margin-left: -$setting-layout-gutter--lgx;
  }

  > .o-grid__col {
    padding-left: $setting-layout-gutter--sm;

    @include mq(md) {
      padding-left: $setting-layout-gutter--md;
    }

    @include mq(lg) {
      padding-left: $setting-layout-gutter--lg;
    }

    @include mq(lgx) {
      padding-left: $setting-layout-gutter--lgx;
    }
  }
}
.o-grid--no-wrap {
  flex-wrap: nowrap;
}

/*  Helpers
----------------------------------------------------------------------------*/
@for $i from 1 through 12 {
  .u-fr-#{$i} {
    width: ($i / 12) * 100%;
    flex-basis: auto;
  }
}
@each $key, $value in $site-breakpoints {
  @for $i from 1 through 12 {
    @include mq($key) {
      .u-fr-#{$i}\@#{$key} {
        width: ($i / 12) * 100%;
        flex-basis: auto;
      }
    }
  }
}

[class^="u-fr-"] {
  .o-grid--equal-height & {
    &.o-grid__col {
      > * {
        height: 100%;
      }
    }
  }
}
