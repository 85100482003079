/*  Block
\*----------------------------------------------------------------------------*/
.m-about {

  .m-featured-block {
    height: auto;

    @include mq(sm) {
      position: relative;
      margin-top: $global-margin * 2;
      margin-left: -#{$global-margin * 2};
    }

    @include mq(sm-only) {
      margin-top: $global-margin * 5;
      margin-left: -#{$global-margin * 4};
    }
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-about__image {
  overflow: hidden;
  border-radius: $global-radius;
}

.m-about__image-wrapper {
  @include mq(sm-only) {
    margin-right: -#{$global-margin * 2 };
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
