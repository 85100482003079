/*  Block
\*----------------------------------------------------------------------------*/
.a-check-list {
  @include clean-list();

}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-check-list__item {
  position: relative;
  padding-bottom: $global-margin / 1.5;
  padding-left: $global-margin;

  @include mq(md) {
    font-size: rem(18);
  }

  &::before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: rem(20);
    height: rem(20);
    background: url('/dist/img/sprite/check-circle.svg') no-repeat;
    content: '';
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
