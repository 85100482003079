/*  Colors
\*----------------------------------------------------------------------------*/
$color-white: #fff;
$color-black: #333;
$color-gray-dark: #6c6c6c;
$color-gray: #e0e6ed;
$color-gray-light: #f6f7f8;

$color-orange: #ea5b0c;
$color-orange-darker: darken(#ea5b0c, 5%);

$color-primary: #112950;
$color-primary-dark: #242d38;
$color-primary-light: lighten($color-primary, 10%);
$color-primary-hover: darken($color-primary, 10%);
$color-secondary: #2991b8;
$color-secondary-hover: darken($color-secondary, 5%);

$color-background: #f6f7f8;
$color-heading: $color-primary;
$color-text: rgba($color-primary, 90%);
$color-anchor: $color-primary;
$color-anchor-hover: $color-secondary;
$color-border-color: $color-gray;

$color-error: #e53e3e;
$color-success: #38a169;

/*  Typography
\*----------------------------------------------------------------------------*/
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-base: rem(18);
$font-size-large: rem(20);
$font-size-small: rem(16);
$font-size-smallest: rem(14);

$font-base-line-height: 1.65;

/*  Dimensions
\*----------------------------------------------------------------------------*/
$global-margin: rem(32);
$global-padding: rem(32);

/*  Globals
\*----------------------------------------------------------------------------*/
$global-radius: 0.6rem;
$global-radius-small: 0.4rem;
$global-box-shadow: 0 10px 20px 6px rgba(0, 0, 0, 0.08);
$global-box-shadow-extend: 0 20px 30px 8px rgba(0, 0, 0, 0.1);

/*  Grid
\*----------------------------------------------------------------------------*/
$setting-container-size: rem(1200);
$setting-container-inner-size: rem(992);
$setting-container-content-size: rem(785);
$setting-header-height: 64px;
$setting-header-height--md: 72px;
$setting-header-height--lg: 96px;

$setting-layout-gutter--sm: rem(16);
$setting-layout-gutter--md: rem(24);
$setting-layout-gutter--lg: rem(24);
$setting-layout-gutter--lgx: rem(32);

$setting-border-radius: 4px;

/*  Animations
\*----------------------------------------------------------------------------*/
$setting-transition: all 0.25s ease-in-out;
$setting-transition-expand: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0.05s, opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0.05s;
$setting-transition-fast: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);

/*  Settings
\*----------------------------------------------------------------------------*/
$var-viewport-height: calc(100vh - #{$setting-header-height});
$var-viewport-height--md: calc(100vh - #{$setting-header-height--md});
$var-viewport-height--lg: calc(100vh - #{$setting-header-height--lg});
