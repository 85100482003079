/*  Block
\*----------------------------------------------------------------------------*/
.m-featured-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: ($global-padding / 1.5) ($global-padding / 2);
  color: $color-white;
  background: $color-anchor;
  border-radius: $global-radius;

  @include mq(sm) {
    padding: ($global-padding * 1.5) $global-padding/1.75;
  }

  @include mq(md) {
    padding: ($global-padding * 1.5) $global-padding * 0.75 $global-padding;
  }

  .t-contact__right & {
    height: auto;
  }

  p {
    width: 100%;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-featured-block__title {
  width: 100%;
  font-size: rem(22);

  @include mq(sm-down) {
    margin-bottom: $global-margin / 2;
  }
}

.m-featured-block__text {
  flex-grow: 1;
  width: 100%;
}

.m-featured-block__list {
  @include clean-list;

  width: 100%;
}

.m-featured-block__openings {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-margin/4;
  padding-bottom: $global-padding/4;
  font-weight: $font-weight-medium;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.m-featured-block__openings-time {
  display: inline-block;
  flex-shrink: 0;
  margin-left: auto;
  font-weight: $font-weight-normal;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.m-featured-block--orange {
  background: $color-orange;

  &.with-bg {
    background-image: url('/dist/img/sprite/clock.svg');
    background-repeat: no-repeat;
    background-position: -30px -20px;
    background-size: 80%;
  }
}

.m-featured-block--white {
  color: inherit;
  background: $color-white;
  box-shadow: $global-box-shadow;

  &.with-bg {
    background-image: url('/dist/img/sprite/clock-grey.svg');
    background-repeat: no-repeat;
    background-position: -30px -20px;
    background-size: 60%;
  }
}

.m-featured-block--blue {
  background: $color-secondary;

  &.with-bg {
    background-image: url('/dist/img/sprite/hexagon.svg');
    background-repeat: no-repeat;
    background-position: -30px -20px;
    background-size: 50%;
  }
}

.m-featured-block--large {
  @include mq(md) {
    padding-right: ($global-padding * 1.5);
    padding-left: ($global-padding * 1.5);
  }
}
