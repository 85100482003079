/*  Block
\*----------------------------------------------------------------------------*/
.o-component {
  margin-bottom: $global-margin * 2;

  &:last-child {
    margin-bottom: 0;
  }
}

section.o-component {
  @include mq(lg) {
    margin-bottom: $global-margin * 3;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/

/*  Modifiers
\*----------------------------------------------------------------------------*/
.o-component--grey {
  padding: $global-margin * 2 0;
  background: $color-background;

  @include mq(md) {
    padding: $global-margin * 3 0;
  }
}
