/*  Block
\*----------------------------------------------------------------------------*/
.zkn-widget-body p {
  font-size: 13px;
}

.zkn-widget-body .fractional_number sup {
  .m-testimonials & {
    vertical-align: initial;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/

/*  Modifiers
\*----------------------------------------------------------------------------*/
