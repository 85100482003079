/*  Block
\*----------------------------------------------------------------------------*/
.o-container {
  width: 100%;
  max-width: $setting-container-size;
  margin: 0 auto;
  padding: 0 $global-padding/3;

  @include mq(md) {
    padding: 0 $global-padding/2;
  }

  &.with-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include mq(lg) {
      flex-direction: row;
    }
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.o-container__head {
  max-width: $setting-container-content-size;
  margin-bottom: $global-margin;

  @include mq(lg) {
    margin-bottom: $global-margin*2.25;
  }
}

.o-container__inner {
  margin-bottom: $global-margin;
  background: $color-white;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;

  > * {
    margin-top: 0;
    margin-bottom: 0;

    + h2,
    + h3 {
      margin-top: $global-margin*1.5;
    }
  }

  > * + * {
    margin-top: $global-margin;
  }

  > h2 + * {
    margin-top: $global-margin*0.75;

    @include mq(lg) {
      margin-top: $global-margin/2;
    }
  }

  > h3 + * {
    margin-top: $global-margin/2;
  }

  p {
    margin-top: $global-margin / 2;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.o-container--content {
  position: relative;
  z-index: 1;
  width: auto;
}

.o-container__inner--center {
  max-width: $setting-container-inner-size;
  margin: 0 auto $global-margin;
  padding: $global-padding $global-padding/2;

  @include mq(md) {
    margin: 0 auto ($global-margin * 2);
    padding: $global-padding;
  }

  @include mq(lg) {
    padding: $global-padding*2.25 calc((#{$setting-container-size} - #{$setting-container-inner-size})/2);
  }
}

.o-container__inner--left {
  padding: $global-padding $global-padding/2;

  @include mq(md) {
    padding: $global-padding;
  }

  @include mq(lg) {
    padding: $global-padding $global-padding*2;
  }
}

.o-container__inner--full {
  max-width: none;
}
