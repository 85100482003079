/*  Block
\*----------------------------------------------------------------------------*/
.o-sidebar {
  width: 100%;

  @include mq(lg) {
    min-width: rem(360);
    max-width: rem(400);
    margin-left: $global-margin;
  }

  .m-featured-block + .m-featured-block {
    margin-top: $global-margin;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/

/*  Modifiers
\*----------------------------------------------------------------------------*/
