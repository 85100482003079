@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

noscript#no-js {
  display: block;
  width: 100%;
  padding: 1rem;
  color: black;
  font-size: 0.9rem;
  background-color: yellow;
}

figure {
  display: block;
  margin: 0;
}

a {
  color: $color-secondary;
}
