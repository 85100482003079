/*  Block
\*----------------------------------------------------------------------------*/
.o-header {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  height: $setting-header-height;
  padding: 0 $global-padding/2;
  background-color: $color-background;
  transition: $setting-transition;

  @include mq(md) {
    height: $setting-header-height--md;
  }

  @include mq(nav-breakpoint) {
    height: $setting-header-height--lg;
    padding: 0 $global-padding/2;
  }

  @include mq(lgx) {
    padding: 0 $global-padding * 1.5;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.o-header__inner {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.o-header__nav-mobile {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  height: 100vh;
  padding: ($global-padding * 2) ($global-padding/4) 0;
  overflow: scroll;
  background: $color-background;
  transition: $setting-transition;

  @include mq(nav-breakpoint) {
    display: none;
  }
}

.o-header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include mq(nav-breakpoint-down) {
    > *:not(.o-header__logo) {
      display: none;
    }
  }
}

.o-header__logo-svg {
  width: 12.325rem;
  height: 1.97rem;

  // 805px
  // 116px

  @include mq(sm) {
    width: 16rem;
    height: 2.55rem;
  }

  @include mq(lgxx) {
    width: 21rem;
    height: 3.34rem;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
