/*  Block
\*----------------------------------------------------------------------------*/
.a-chat {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background: #25d366;
  border-radius: 50%;
  box-shadow: 0 10px 20px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(200%) scale(1);
  animation: popUp 0.5s 2.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: pulse 2s 5;
    content: "";
  }

  &:hover,
  &:focus {
    svg {
      transform: scale(1.05);
    }
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-chat__svg {
  width: 2rem;
  height: 2rem;
  color: white;
  transition: $setting-transition;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
@keyframes popUp {
  from {
    transform: translateY(200%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
    transform: scale(1);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(37, 211, 102, 0);
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
    transform: scale(1);
  }
}
