/*  Block
\*----------------------------------------------------------------------------*/
.o-form {
  margin: $global-margin * 1.5 0;
}

/*  Elements
\*----------------------------------------------------------------------------*/
.o-form__field {
  display: flex;
  flex-direction: column;

  + .o-form__field {
    margin-top: $global-margin/2;
  }

  @include mq(lg) {
    flex-direction: row;

    > * {
      width: 70%;
    }

    > *:first-child {
      width: 30%;
      margin-top: $global-margin/3;
      padding-right: $global-padding;
    }

    + .o-form__field {
      margin-top: $global-margin;
    }
  }
}

.o-form__label {
  margin-bottom: $global-margin/3;
  font-weight: $font-weight-medium;
}

.o-form__input {
  width: 100%;
  padding: $global-padding/3 $global-padding/2;
  border: 1px solid $color-border-color;
  border-radius: 5px;

  &:focus {
    border-color: $color-secondary;
  }

  .has-error & {
    border-color: red;
  }
}

.o-form__radio-label {
  display: inline-flex;
  padding: $global-padding/3;
  background: $color-white;
  border: 1px solid $color-border-color;
  cursor: pointer;
}

.o-form__radio {
  &:focus {
    .o-form__radio-label {
      color: $color-secondary;
    }
  }

  input {
    @include element-visually-hidden();

    &:checked + .o-form__radio-label {
      color: $color-secondary;
      font-weight: $font-weight-medium;
      border: 2px solid $color-secondary;
    }

    &:focus {
      + .o-form__radio-label {
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.o-form__submit {
  margin-top: $global-margin/2;

  @include mq(lg) {
    margin-left: 30%;
  }
}

.o-form__error-list {
  @include clean-list();

  margin-top: $global-margin/6;
  color: red;
  font-size: $font-size-smallest;
}

.o-form__error-item {
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.o-form--options {
  + .o-form--options {
    @include mq(lg) {
      margin-top: $global-margin/4;
    }
  }
}
