/*  Block
\*----------------------------------------------------------------------------*/
.a-map {
  position: relative;
  margin-bottom: $global-margin;
  padding-bottom: percentage(9/16);
  overflow: hidden;
}

/*  Elements
\*----------------------------------------------------------------------------*/
.a-map__iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
