/*  Block
\*----------------------------------------------------------------------------*/
.m-testimonials {
  align-items: center;
  justify-content: space-between;
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-testimonials__container {
  &.in-view .m-testimonials__item {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}

.m-testimonials__widget {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: $global-padding*0.75 ($global-padding / 2);
  padding-right: $global-padding;
  border: 1px solid $color-gray;
  border-radius: $global-radius-small;
}

.m-testimonials__widget-wrapper {
  position: absolute;
  top: -32px;
  z-index: -1;
  width: rem(55);

  // Hide part of widget
  &::after,
  &::before {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    background: $color-white;
    content: '';
  }

  &::after {
    top: 0;
    height: 28%;
  }

  &::before {
    bottom: 0;
    height: 22%;
  }
}

.m-testimonials__widget-img {
  position: relative;
  width: rem(55);
  max-height: rem(125);
  object-fit: cover;
}

.m-testimonials__widget-logo {
  flex-shrink: 0;
  max-width: rem(140);
  height: rem(25);
  margin-left: $global-margin * 2;
}

.m-testimonials__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ($global-margin / 2) ($global-margin / 2);
  padding: $global-padding $global-padding ($global-padding / 2);
  border: 1px solid $color-gray;
  border-radius: $global-radius;
  box-shadow: 0 6px 15px rgba($color-gray, 0.5);
  transform: scale(0.9);
  opacity: 0;
  transition: $setting-transition;
  transition-delay: 0.5s;

  .swiper-slide-active & {
    transform: scale(1);
    opacity: 1;
  }

  @include mq(md) {
    &:nth-child(even) {
      position: relative;
      left: -#{$global-margin};
    }
  }
}

.m-testimonials__wrapper {
  position: relative;
}

.m-testimonials__icon {
  position: absolute;
  top: -#{$global-margin/4};
  left: -#{$global-margin/2};
  width: rem(80);
  height: rem(65);
  margin-bottom: $global-margin / 2;
  opacity: 0.1;
}

.m-testimonials__content {
  margin-bottom: $global-margin/2;
  font-weight: $font-weight-medium;
  font-size: $font-size-small;

  @include mq(lg) {
    font-size: $font-size-base;
  }
}

.m-testimonials__footer {
  display: flex;
  align-items: center;
}

.m-testimonials__description {
  display: block;
  margin-top: $global-margin / 8;
  font-size: rem(14);
  opacity: 0.8;
}

.m-testimonials__image {
  width: rem(40);
  height: rem(40);
  margin-right: $global-margin / 2;
  overflow: hidden;
  border-radius: 50%;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
