/*  Block
\*----------------------------------------------------------------------------*/
.m-slider {

}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-slider__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 $global-margin/2;
}

.m-slider__nav {
  display: flex;
}

.m-slider__nav-item {
  color: $color-gray;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-anchor;
  }

  svg {
    transition: $setting-transition;
  }
}

.m-slider__nav-left {
  margin-right: $global-margin/2;

  svg {
    transform: rotate(180deg);
  }
}

.m-slider__dots {
  display: flex;
  height: rem(3);
}

.swiper-pagination-bullet {
  width: $global-margin;
  height: rem(3);
  background: $color-gray;
  border-radius: 0;
  opacity: 1;
  transition: $setting-transition;

  &:not(:last-of-type) {
    margin-right: $global-margin/8;
  }
}

.swiper-pagination-bullet-active {
  background: $color-anchor;
}


/*  Modifiers
\*----------------------------------------------------------------------------*/
