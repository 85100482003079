/*  Block
\*----------------------------------------------------------------------------*/

/*  Elements
\*----------------------------------------------------------------------------*/

.t-contact__row {
  display: flex;

  @include mq(md-down) {
    flex-direction: column;
  }
}

.t-contact__left {
  padding: $global-padding $global-padding/2;
  background: $color-background;

  @include mq(md) {
    padding: $global-padding;
  }

  @include mq(lg) {
    max-width: rem(750);
    padding-right: $global-padding * 2;
  }
}

.t-contact__right {
  margin-top: 0;
  padding: $global-padding;

  @include mq(md-down) {
    order: -1;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
