// =============================================================================
// Font Face (https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6)
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-weight: $weight;
    font-family: quote($name);
    font-style: $style;
    src: $src;
    font-display: swap;
  }
}

// =============================================================================
// Clean list
// =============================================================================

@mixin clean-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}

// =============================================================================
// Clean button
// =============================================================================

@mixin clean-button() {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-align: inherit;
  background: none;
  border: none;
  appearance: none;
}

// =============================================================================
// Clean link
// =============================================================================

@mixin clean-link() {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// =============================================================================
// Center element
// =============================================================================

@mixin center-element() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

// =============================================================================
// Background-overlay
// =============================================================================

@mixin background-overlay($gradient: false) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  transition: $setting-transition;
  content: '';

  @if $gradient == "true" {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 100%);
  }
}

// =============================================================================
// Element visually hidden
// =============================================================================
@mixin element-visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap; /* added line */
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
