/**
* set a custom default :focus style
*/

:focus {
  outline: none;
}

/* keyboard */
[data-whatintent='keyboard'] :focus {
  outline: 1px solid $color-secondary;
  box-shadow: 0 0 10px $color-secondary;
}
