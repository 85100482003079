/*  Block
\*----------------------------------------------------------------------------*/
.m-nav {
  @include clean-list();

  @include mq(nav-breakpoint) {
    display: flex;
    align-items: baseline;
    margin: 0 -#{$global-margin/3};
  }

  @include mq(lgx) {
    margin: 0 -#{$global-margin/2};
  }

  .o-header__nav-mobile & {
    transform: translateY($global-margin*2);
    opacity: 0;
    transition: all 0.375s cubic-bezier(0.1, 1, 0.5, 1);
    animation: fadeIn 0.5s 0.15s forwards;
  }
}

/*  Elements
\*----------------------------------------------------------------------------*/
.m-nav__item {
  padding: 0 $global-padding/3;

  @include mq(lgx) {
    padding: 0 $global-padding/2;
  }
}

.m-nav__link {
  color: $color-text;
  font-weight: $font-weight-medium;
  font-size: rem(18);
  text-decoration: none;
  transition: $setting-transition;

  .o-header__nav-mobile & {
    display: inline-block;
    margin-bottom: $global-margin*0.75;
    font-size: rem(20);
  }

  &:hover,
  &:focus {
    color: $color-anchor;
    box-shadow: 0 rem(2) $color-anchor;
  }
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.m-nav__link--active {
  color: $color-anchor;
  box-shadow: 0 rem(2) rgba($color-anchor, 0.5);
}

.m-nav__link--highlighted {
  @extend %btn;
  @extend %a-btn--secondary;

  padding: rem(8) rem(16);
  color: $color-primary;

  &:focus,
  &:hover {
    color: $color-primary;
  }
}
