/*  Block
\*----------------------------------------------------------------------------*/
.a-alert {
  display: block;
  margin-bottom: $global-margin;
  padding: $global-padding/2;
  font-weight: $font-weight-medium;
  font-size: $font-size-smallest;
  background: $color-gray;
  border: 1px solid $color-gray-dark;
  border-radius: $global-radius-small;
}

/*  Elements
\*----------------------------------------------------------------------------*/

/*  Modifiers
\*----------------------------------------------------------------------------*/
.a-alert--success {
  color: $color-success;
  background: rgba($color-success, 0.1);
  border-color: $color-success;
}

.a-alert--error {
  color: $color-error;
  background: rgba($color-error, 0.1);
  border-color: $color-error;
}
