/*  Block
\*----------------------------------------------------------------------------*/
.a-image {
  position: relative;
  display: block;
  width: 100%;
}

/*  Elements
\*----------------------------------------------------------------------------*/

.a-image__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  transition: opacity 0.3s ease, transform 1s ease-out;

  &.no-crop {
    position: relative;
  }

  &.lazy {
    &.initial,
    &.loading,
    &.lazy {
      opacity: 0;
    }

    &.loaded {
      opacity: 1;
    }
  }
}

.a-image__wrapper {
  position: relative;
  overflow: hidden;
}

.a-image__figcaption {
  margin-top: $global-margin/4;
  font-size: $font-size-small;
}

/*  Modifiers
\*----------------------------------------------------------------------------*/
.a-image--contain {
  .a-image__img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
}

.a-image--small {
  max-width: rem(500);
}
